import React from "react";
import styled from "styled-components";
import { Link } from 'gatsby'
import { useEnquiryData } from "../../hooks/enquiry";
const TourofRealSchool = () => {

	const data = useEnquiryData()
	let enquiryData = data.filter((item) => item.slug === "international")
    return (
        <CustomCounsellingStyled>
            <CustomCounsellingWrapperStyled>
            <p key={`heading`} id="custom_counselling_heading" dangerouslySetInnerHTML={{ __html: enquiryData[0]?.heading }} />

                <CustomCounsellingOptionsWrapperStyled>
						<div className="custom_counselling_options option_one">
							<img src={enquiryData[0]?.media?.url} alt="" />
						</div>
                    <div>
                        <div className="custom_counselling_options option_one">
							<p className="p-heading" dangerouslySetInnerHTML={{ __html:enquiryData[0]?.sub_heading}} />
                            
                        </div>
                    </div>

				</CustomCounsellingOptionsWrapperStyled>
				<Link to={enquiryData[0]?.Button?.redirect_to}>
				<ScheduleSessionBtnStyled>{ enquiryData[0]?.Button?.heading}</ScheduleSessionBtnStyled>
				</Link>
            </CustomCounsellingWrapperStyled>
        </CustomCounsellingStyled>
    )
}

const CustomCounsellingStyled = styled.section`
	padding-left: 7.5em;
	padding-right: 7.5em;
	padding-bottom: 7.5em;

	#custom_counselling_heading {
		width: 60%;
		margin: 0 20% 30px;
		font-family: "Inter";
		text-align: center;
		font-style: normal;
		font-weight: 900;
		font-size: 2.5rem;
		line-height: 58px;
		color: #2a264c;
	}
	@media (max-width: 980px) {
		padding-left: 4.5em;
		padding-right: 4.5em;
		#custom_counselling_heading {
			font-size: 2.6rem;
			width: 100%;
			margin: 0 0 30px;
		}
	}
	@media (max-width: 730px) {
		padding: 1.75em;
		
	}
	@media (max-width: 600px) {
		#custom_counselling_heading {
			font-size: 2rem;
			width: 100%;
			margin: 0 0 30px;
		}
	}
	@media (max-width: 500px) {
		padding: 1.15em;
		#custom_counselling_heading {
			width: 100%;
			margin: 0 0 30px;
			font-size: 1.75rem;
			line-height: 34px;
		}
	}
`;
const CustomCounsellingWrapperStyled = styled.div`
	background: #fff0eb;
	border-radius: 32px;
	padding: 3em 3.5em;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	

	@media (max-width: 1070px) {
		#custom_counselling_heading {
			text-align: center;
		}
	}
	@media (max-width: 730px) {
		padding: 3em;
	}
	@media (max-width: 980px) {
		padding: 3em 2.5em;
	}
	@media (max-width: 500px) {
		background: #ffffff;
		padding: 2em 0.5em;
		
	}

	@media (max-width: 353px) {
		padding: 2em 1em;
		#custom_counselling_heading {
			font-size: 1.4rem;
		}
	}
`;
const ScheduleSessionBtnStyled = styled.button`
	outline: none;
	border: none;
	margin-top: 1.5em;
	cursor: pointer;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 24px;
	color: #2a264c;
	padding: 1em 2em;
	background: #f9ce4b;
	border-radius: 12px;
	@media (max-width: 500px) {
		font-size: 1rem;
		line-height: 19px;
		padding: 0.90625em 1.84375em;
	}
	@media (max-width: 315px) {
		font-size: 0.8rem;
	}
`;
const CustomCounsellingOptionsWrapperStyled = styled.div`
	margin: 1.75em 0;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4em;
	flex-wrap: wrap;
	.custom_counselling_options {
		/* display: flex;
		align-items: center;
		justify-content: center; */
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;

		gap: 1em;

		min-width: 20.375em;
		max-width: 25.375em;
	}
	.p-heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.25rem;
		line-height: 1.5rem;
		text-align: left;
		color: #2a264c;
	}

	.p-sub-heading {
		margin-left: 10px;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: left;
		color: #2a264c;
	}
	@media (max-width: 600px) {
		.p-heading {
			font-size: 1rem;
		}
		.p-sub-heading {
			font-size: 1rem;
		}
	}

	@media (max-width: 500px) {
		gap: 2em;
		.custom_counselling_options {
			flex-direction: column;
		}
		/* .custom_counselling_options > p {
			font-size: 1rem;
			line-height: 19px;
			text-align: center;
			font-weight: 500;
		} */
		.p-heading {
			font-size: 1.2rem;
			line-height: 19px;
			text-align: center;
			font-weight: 500;
		}
		.p-sub-heading {
			font-size: 1rem;
			line-height: 19px;
			text-align: center;
			font-weight: 500;
		}
		.option_one {
			order: 2;
		}
		.option_two {
			order: 1;
		}
		.option_three {
			order: 3;
		}
	}

	@media (max-width: 353px) {
		margin-top: 1.75em;
		.custom_counselling_options {
			min-width: 100%;
			max-width: 100%;
		}
	}
`;
export default TourofRealSchool