import { useStaticQuery, graphql } from "gatsby";

export const useFitCurriculumData = () => {
  const { allStrapiFitCurriculum } = useStaticQuery(
    graphql`
      query fitCurriculumquery {
        allStrapiFitCurriculum {
          nodes {
            fit_curriculum {
              heading
              sub_heading
              img {
                url
              }
              inner_heading
              redirect_to
            }
          }
        }
      }
    `
  );
  return allStrapiFitCurriculum.nodes;
};
