import { useStaticQuery, graphql } from "gatsby";

export const useEnquiryData = () => {
    const { allStrapiEnquiry } = useStaticQuery(
        graphql`
        query EnquiryQuery{
            allStrapiEnquiry{
                nodes{
                    heading
                    sub_heading
                    slug
                    media{
                        url
                    }
                    Button{
                        redirect_to
                        heading
                    }
                }
            }
        }
        `
    )
    return allStrapiEnquiry.nodes
}