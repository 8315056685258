import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useFitCurriculumData } from "../../hooks/firCurriculum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";

const detail = {
  heading: "Is this curriculum and pedagogy fit for me?",
  subHeading:
    "As a parent would you want an international curriculum for your child? Use the tool below and evaluate your fit ",
  img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/76cb0d2e18b077dcb19714b2ee0ee6e9.png",
};

const Curriculum = () => {
  const data = useFitCurriculumData();
  const fitCurriculumData = data[0].fit_curriculum;
  return (
    <ScheduleSessionStyled>
      <div id="help_you_make_right_choice">
        <p id="help_you_make_right_choice_head1" dangerouslySetInnerHTML={{__html: fitCurriculumData?.heading}} />
        <span
          dangerouslySetInnerHTML={{ __html: fitCurriculumData?.sub_heading }}
          className="p-sub-heading"
        />
        <OnlyWeCanDoItCardWrapperStyled>
          <div key={detail.id}>
            <div className="only_we_can_do_it_card" key={detail.id}>
              <img src={fitCurriculumData?.img?.url} alt="" />
						  <p dangerouslySetInnerHTML={{ __html: fitCurriculumData?.inner_heading }} />
              <Link
                to={fitCurriculumData?.redirect_to}
    className="redirect"
              >
							  <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/3694fd6b0a7b0e49c8799221ffba4461.png"/>
              </Link>
            </div>
          </div>
        </OnlyWeCanDoItCardWrapperStyled>
      </div>
    </ScheduleSessionStyled>
  );
};
const ScheduleSessionStyled = styled.section`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	margin-bottom: 7.5rem;
	border-radius: 12px;
	#help_you_make_right_choice {
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2rem 15.875rem;
	}
	#schedule_session {
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #2a264c;
		border: none;
		outline: none;
		background: #f9ce4b;
		border-radius: 8px;
		padding: 0.90625rem 1.75rem;
	}
	.p-sub-heading {
		margin-left: 10px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 20px;
		text-align: center;
		color: #2A264C;
	}

	#help_you_make_right_choice_head1 {
		margin-top: 1rem;
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 48px;
		line-height: 58px;
		text-align: center;
		color: #2a264c;
		margin-bottom: 0.75rem;
	}
	
	@media (max-width: 1200px) {
		#help_you_make_right_choice {
			padding: 2rem 0rem;
			width: 100%;
		}
	}
	@media (max-width: 500px) {
		#help_you_make_right_choice {
			padding: 2rem;
		}
		#help_you_make_right_choice_head1 {
			font-weight: 700;
			font-size: 1.5rem;
			line-height: 29px;
		}
		#help_you_make_right_choice_head2 {
			font-size: 0.875rem;
			line-height: 17px;
			font-weight: 400;
		}
	}
	@media (max-width: 325px) {
		#help_you_make_right_choice {
			padding: 1rem;
		}
		#help_you_make_right_choice_head1 {
			font-size: 1.3rem;
		}
	}
	@media (max-width: 313px) {
		#help_you_make_right_choice {
			padding: 0.5rem;
		}
	}
`;
const OnlyWeCanDoItCardWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  flex-wrap: wrap;
  width: 100%;
 .redirect img{

 padding:10px;
 }
  .only_we_can_do_it_card {
	align-items:center;
    margin: 2em 0;
    background: #fff0eb;
    border-radius: 16px;
    padding: 1.5em 3em;
    min-height: 14rem;
  }

  .only_we_can_do_it_card img {
		margin-left: 47px;
  }

  .only_we_can_do_it_card h3 {
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    color: #2a264c;
  }
  .only_we_can_do_it_card p {
    margin-top: 1rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #2a264c;
  }

  @media (max-width: 1040px) {
    .only_we_can_do_it_card {
      min-width: 28.125em;
    }
    .only_we_can_do_it_card p {
      max-width: none;
      min-width: none !important;
    }
  }
  @media (max-width: 500px) {
    gap: 1.5em;
    .only_we_can_do_it_card {
      min-width: 100%;
      /* background: #ffffff; */
      padding: 1em;
    }
    .only_we_can_do_it_card p {
      max-width: none;
      min-width: none !important;
    }
  }
  @media (max-width: 340px) {
    .only_we_can_do_it_card {
      min-width: 100%;
    }

    .only_we_can_do_it_card p {
      font-size: 0.8rem;
      max-width: none;
      min-width: none !important;
    }
  }
`;
export default Curriculum;
