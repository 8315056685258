import React from "react";
import styled from "styled-components";
import { useStudentOutcomeData } from "../../hooks/studentOutcome";

const OurFocus = () => {
	const data = useStudentOutcomeData()
	const studentData = data[0]
    const heading = "Student Outcomes we focus on";
    return (
        <OnlyWeCanDoItStyled>
            <OnlyWeCanDoItwrapperStyled>
                <p
                    key={`heading`}
                    id="only_we_can_do_it_wrapper_heading_1"
                    dangerouslySetInnerHTML={{ __html: studentData?.heading }}
                />
                <OnlyWeCanDoItCardWrappersStyled>
                    <OnlyWeCanDoItCardWrapperStyled>
						{studentData?.features?.map((detail) => {
                            return (
                                <div key={detail.id}>
                                    <div className="only_we_can_do_it_card" key={detail.id}>
                                        <img
                                        src={detail.media.url}
                                        />
                                        <h3
                                            key={`heading`}
                                            dangerouslySetInnerHTML={{
												__html: detail.Title,
                                            }}
                                        />
                                        <p
                                            key={`subHeading`}
                                            dangerouslySetInnerHTML={{
												__html: detail.Description,
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </OnlyWeCanDoItCardWrapperStyled>
                </OnlyWeCanDoItCardWrappersStyled>
            </OnlyWeCanDoItwrapperStyled>
        </OnlyWeCanDoItStyled>
    );
};
const OnlyWeCanDoItStyled = styled.section`
margin-top: 3rem;
	padding: 0 1em;
	@media ( max-width : 500px ) {
		padding: 5rem 1rem 2rem;
	}
`;
const OnlyWeCanDoItwrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding-bottom: 7em;
	padding-left: 1.25em;
	padding-right: 1.25em;
	@media ( max-width : 500px ) {
		padding-bottom: 0rem;
	}
	#only_we_can_do_it_wrapper_heading_1 {
		color: #2A264C;
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		width: 100%;
		margin-bottom: 0.4em;
	}
	#only_we_can_do_it_wrapper_heading_2 {
		color: #396eb0;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		width: 100%;
		margin-bottom: 4em;
	}

	@media (max-width: 500px) {
		#only_we_can_do_it_wrapper_heading_1 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		#only_we_can_do_it_wrapper_heading_2 {
			font-size: 1rem;
			line-height: 19px;
			margin-bottom: 3em;
		}
	}
	@media (max-width: 340px) {
		#only_we_can_do_it_wrapper_heading_1 {
			font-size: 1.65rem;
		}
	}
	@media (max-width: 316px) {
		#only_we_can_do_it_wrapper_heading_1 {
			font-size: 1.35rem;
		}
	}
`;

const OnlyWeCanDoItCardWrappersStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 2em;
	flex-wrap: wrap;
	width: 100%;
	margin-top: 2rem;
	@media (max-width: 500px) {
		gap: 1.5em;
	}
`;

const OnlyWeCanDoItCardWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2em;
	flex-wrap: wrap;
	width: 100%;

	.only_we_can_do_it_card {
		border-radius: 16px;
		padding: 1.5em 3em;
		min-height: 12rem;
	}
	.only_we_can_do_it_card h3 {
		text-align: center;
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.25rem;
		color: #2a264c;
	}
	.only_we_can_do_it_card p {
		margin-top: 1rem;
		text-align: center;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #2a264c;
		max-width: 23rem;
		/* min-width: 23rem; */
	}

	@media (max-width: 1040px) {
		.only_we_can_do_it_card {
			min-width: 28.125em;
		}
		.only_we_can_do_it_card p {
			max-width: none;
			min-width: none !important;
		}
	}
	@media (max-width: 500px) {
		gap: 1.5em;
		.only_we_can_do_it_card {
			min-width: 100%;
			/* background: #ffffff; */
			padding: 1em;
		}
		.only_we_can_do_it_card p {
			max-width: none;
			min-width: none !important;
		}
	}
	@media (max-width: 340px) {
		.only_we_can_do_it_card {
			min-width: 100%;
		}

		.only_we_can_do_it_card p {
			font-size: 0.8rem;
			max-width: none;
			min-width: none !important;
		}
	}
`;
export default OurFocus;
