import { useStaticQuery, graphql } from "gatsby";

export const useStudentOutcomeData = () => {
    const { allStrapiStudentOutcome } = useStaticQuery(
        graphql`
      query studentOutcomeQuery {
        allStrapiStudentOutcome {
          nodes {
            heading
            features{
                Title
                Description
                media{
                    url
                }
            }
          }
        }
      }
    `
    );
    return allStrapiStudentOutcome.nodes;
};
