import React from "react";
import styled from "styled-components";
import {useParentHeader} from '../../hooks/parent-review-header'
import { useProjectListData } from "../../hooks/project-list";
import { Link } from "gatsby";

const HandsOn = () => {
	const allData = useProjectListData()
	const header = useParentHeader()
	const headingData = header.filter((item) => item.page_type === "international-page-project")
	const handsonData = allData.filter((item) => item.slug === "international-page").slice(0,3)
	const {heading} = headingData[0] ?? ""
    return (
        <EducationalGoalStyled>
            <EducationalGoalWrapperStyled>
                <h3 key={`heading`} id="custom_counselling_heading" dangerouslySetInnerHTML={{ __html: heading }} />

                <EducationalGoalCardWrapperStyled>
					{handsonData?.map((list, index) => {
                        return (
                            <EducationalGoalCardStyled key={list.id}>
								<div className="image-wrapper">
									<img src={list?.thumbnail?.url} alt="" />
								</div>
								<span className="evaluate_profile_name">{list?.heading}</span>
                            </EducationalGoalCardStyled>
                        );
                    })}
                   
                </EducationalGoalCardWrapperStyled>
                <Link
                    to='/project-result'
                >
					<button id="schedule_session">View All</button>
                </Link>
            </EducationalGoalWrapperStyled>
            
        </EducationalGoalStyled>
    );
};
const EducationalGoalStyled = styled.section`
	margin-top: 2rem;
	padding: 0 1em;
`;

const EducationalGoalWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 4em;
	padding-bottom: 7em;
	& h3 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #2a264c;
	}
    #schedule_session {
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #2a264c;
		border: none;
		outline: none;
		background: #f9ce4b;
		border-radius: 8px;
		padding: 0.90625rem 1.75rem;
	}

	@media (max-width: 500px) {
		gap: 1em;
		padding-bottom: 2rem;
		& h3 {
			font-size: 1.75rem;
			line-height: 34px;
		}
	}
`;

const EducationalGoalCardWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2em;
	flex-wrap: wrap;
	width: 100%;
	.image-wrapper{
		display:flex;
		overFlow:hidden;
	}
	@media (max-width: 500px) {
		gap: 1em;
	}
	@media (max-width: 317px) {
		gap: 0.4em;
	}
`;

const EducationalGoalCardStyled = styled.div`
	min-width: 20em;
	max-width: 20em;
	min-height: 14em;
	max-height: 14em;
	padding: 1em 3.34375em;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	gap: 0.5em;
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	overflow:hidden;

	& img {
		min-width: 20em;
		max-width: 20em;
	}
	.evaluate_profile_name {
		min-width: 15em;
		max-width: 15em;
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: left;
		color: #2A264C;
		max-width: 12rem;
	}

	.click_arrow {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		background: #f1f1f1;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.fa-angle-right {
		color: #2A264C;
		font-size: 1.2rem;
		height: 1em;
	}
	@media (max-width: 500px) {

		.evaluate_profile_name {
			font-size: 1rem;
			line-height: 19px;
		}
	}

	@media (max-width: 353px) {
		min-width: 7em;
		max-width: 7em;

		.evaluate_profile_name {
			font-size: 0.7rem;
		}
	}
	@media (max-width: 317px) {
		& img {
			height: 40px;
			width: 40px;
		}
	}
`;
export default HandsOn;
