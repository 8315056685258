import React from "react";
import Enquiry from "../commonComponent/enquiry";
import Hero from "../commonComponent/hero"
import PersonalizedClass from "../commonComponent/personlizedClass";
import HandsOn from './handsonProject'
import OurFocus from "./ourFocus";
import HowToApply from "../commonComponent/howToapply";
import ParentFeedback from "./parentFeedback";
import Curriculum from "../commonComponent/curriculum";
import CurriculumFit from "./curriculumFit";
import ProgramsOpen from "../commonComponent/programsOpen";
import TourofRealSchool from "./tourofrealschool";
const ProgramIndex = () => {
    return (
        <>
            <Hero slug="Programpage"/>
            <PersonalizedClass slug="Programpage" />
            <Enquiry slug="Program-Page-Enquiry" />
            <HandsOn />
            <Curriculum slug="ProgramPage" />
            <OurFocus />
            <ProgramsOpen slug="Program-Page" />
            <HowToApply slug="Program-Page" />
            <ParentFeedback />
            <TourofRealSchool />
            <CurriculumFit />
        </>
    )
}

export default ProgramIndex